import React from 'react';

interface IPaginationProps {
  totalPages: number;
  currentPage: number;
  handlePageChange: (page: number) => void;
}

const Pagination: React.FC<IPaginationProps> = ({ totalPages, currentPage, handlePageChange }) => {
  return (
    <div className="flex justify-center mt-4">
      {totalPages < 10 ? (
        <>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {index + 1}
            </button>
          ))}
        </>
      ) : (
        <>
          {currentPage > 1 && (<button
            onClick={() => handlePageChange(1)}
            className={`mx-1 px-3 py-1 rounded ${currentPage === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            1
          </button>)}
          {currentPage > 3 && <span className="mx-1">...</span>}
          {currentPage > 2 && (
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className={`mx-1 px-3 py-1 rounded ${currentPage === currentPage - 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {currentPage - 1}
            </button>
          )}
          <button
            onClick={() => handlePageChange(currentPage)}
            className={`mx-1 px-3 py-1 rounded bg-blue-500 text-white`}
          >
            {currentPage}
          </button>
          {currentPage < totalPages - 1 && (
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className={`mx-1 px-3 py-1 rounded ${currentPage === currentPage + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {currentPage + 1}
            </button>
          )}
          {currentPage < totalPages - 2 && <span className="mx-1">...</span>}
          {totalPages > 1 && (currentPage < totalPages) && (
            <button
              onClick={() => handlePageChange(totalPages)}
              className={`mx-1 px-3 py-1 rounded asdf ${currentPage === totalPages ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {totalPages}
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default Pagination;