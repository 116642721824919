import { IUserInfo } from "./types";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export interface ILoginData {
  email: string;
  password: string;
}

export interface IChangePasswordData {
  email: string;
  oldPassword: string;
  newPassword: string;
}

export interface IErrorResponse {
  detail: string;
}
interface ISignResponseDataUserInfo extends IUserInfo {
  _id: string;
}

export interface ISignResponseData {
  detail: any;
  message: string;
  user: ISignResponseDataUserInfo
}

export interface IAdminSignResponseData {
  token: string;
}

export interface ILog {
  _id: string;
  action: string;
  details: string;
  api_key: string;
  status: number;
  model: string;
  cost: number;
  timestamp: string;
}

// Type guard for IErrorResponse
export function isIErrorResponse(data: any): data is IErrorResponse {
  return (data as IErrorResponse).detail !== undefined;
}

// Type guard for ISignResponseData
export function isISignResponseData(data: any): data is ISignResponseData {
  return (data as ISignResponseData).user !== undefined;
}

export async function signin(data: ILoginData): Promise<ISignResponseData | IErrorResponse> {
  const response = await fetch(`${backendUrl}signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  return result;
}

export async function signup(data: ILoginData): Promise<ISignResponseData> {
  const response = await fetch(`${backendUrl}signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  return result;
}

export async function changePassword(apikey: string = "", data: IChangePasswordData): Promise<ISignResponseData> {
  const response = await fetch(`${backendUrl}change_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API_KEY': apikey
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  return result;
}

export async function getUserInfo(apikey: string): Promise<ISignResponseData> {
  const response = await fetch(`${backendUrl}get_user_info`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'API_KEY': apikey
    }
  });
  const result = await response.json();
  return result;
}

export async function addUserAPIKey(apikey: string): Promise<ISignResponseData> {
  const response = await fetch(`${backendUrl}add_api_key`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'API_KEY': apikey
    }
  });
  const result = await response.json();
  return result;
}

export async function deleteUserAPIKey(apikey: string, key: string): Promise<ISignResponseData> {
  const response = await fetch(`${backendUrl}delete_api_key`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API_KEY': apikey
    },
    body: JSON.stringify({ key })
  });
  const result = await response.json();
  return result;
}

export async function getActivityLogs(apikey: string): Promise<Array<ILog>> {
  const response = await fetch(`${backendUrl}get_logs`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'API_KEY': apikey
    }
  });
  const result = await response.json();
  return result?.logs || [];
}

export async function adminSignin(email: string, password: string): Promise<IAdminSignResponseData> {
  const response = await fetch(`${backendUrl}admin/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  });
  const result = await response.json();
  return result;
}

export async function adminGetUsers(token: string) {
  const response = await fetch(`${backendUrl}admin/get_users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
  const result = await response.json();
  return result;
}

export async function adminDeleteUser(token: string, _id: string) {
  const response = await fetch(`${backendUrl}admin/delete_user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ _id })
  });
  const result = await response.json();
  return result;
}

export async function adminResetPassword(token: string, _id: string) {
  const response = await fetch(`${backendUrl}admin/reset_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ _id })
  });
  const result = await response.json();
  return result;
}
