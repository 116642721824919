import { IUser } from "../../utils/types";

const AdminUserDetailCard: React.FC<{ user: IUser }> = ({ user }) => {
  return (
    <div className="p-5 sm:p-10 rounded-[16px] md:rounded-[30px] bg-white flex flex-col items-start w-[50%]">
      <p className="font-semibold text-[20px] mb-4">User Detail</p>
      <p className="text-sm font-medium">ID: {user?._id}</p>
      <p className="text-sm font-medium">Email: {user?.email}</p>
      <p className="text-sm font-medium">Credit: {user?.credit}</p>
      <p className="text-sm font-medium">Request Count: {user?.request_count}</p>
      <p className="text-sm font-medium">Created Date: {user?.created_date ? new Date(user?.created_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).replace(',', '') : 'N/A'}</p>
    </div>
  )
}

export default AdminUserDetailCard;