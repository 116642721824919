import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser, IUserInfo, ModalType } from './types';
import { getActivityLogs, getUserInfo, ILog } from './apiServices';

interface IAppContextType {
  openedModal: ModalType;
  setModalStatus: (modal: ModalType) => void;
  signOut: () => void;
  userInfo: IUserInfo;
  setUserInfo: (userInfo: IUserInfo) => void;
  isConfirmDeleteAPIKey: boolean;
  setIsConfirmDeleteAPIKey: (isConfirmDeleteAPIKey: boolean) => void;
  isConfirmDeleteUser: boolean;
  setIsConfirmDeleteUser: (isConfirmDeleteUser: boolean) => void;
  isConfirmPasswordReset: boolean;
  setIsConfirmPasswordReset: (isConfirmPasswordReset: boolean) => void;
  updateUserInfo: () => void;
  getLogs: () => void;
  logs: ILog[];
  adminToken: string;
  setAdminToken: (token: string) => void;
  adminUsers: IUser[];
  setAdminUsers: (users: IUser[]) => void;
  logsLoading: boolean;
}

const AppContext = createContext<IAppContextType | undefined>(undefined);

interface IAppContextProviderProps {
  children: ReactNode;
}

export const AppContextProvider: React.FC<IAppContextProviderProps> = ({ children }) => {
  const [openedModal, setModalStatus] = useState<ModalType>(ModalType.NONE);
  const [isConfirmDeleteAPIKey, setIsConfirmDeleteAPIKey] = useState<boolean>(false);
  const [isConfirmDeleteUser, setIsConfirmDeleteUser] = useState<boolean>(false);
  const [isConfirmPasswordReset, setIsConfirmPasswordReset] = useState<boolean>(false);
  const [userInfo, setUserContextInfo] = useState(() => {
    const userInfo = localStorage.getItem('userInfo');
    return userInfo ? JSON.parse(userInfo) : { email: '', auth_key: [], credit: 0 };
  });
  const navigate = useNavigate();
  const [logs, setLogs] = useState<ILog[]>([]);
  const [adminToken, setAdminToken] = useState<string>('');
  const [adminUsers, setAdminUsers] = useState<IUser[]>([]);
  const [logsLoading, setLogsLoading] = useState<boolean>(false);

  const updateUserInfo = useCallback(async () => {
    try {
      const updatedUserInfo = await getUserInfo(userInfo?.api_keys?.[0].key || "");
      setUserInfo({
        email: updatedUserInfo.user.email,
        api_keys: [{
          key: updatedUserInfo.user._id,
          created: updatedUserInfo.user.created_date || ""
        }, ...(updatedUserInfo.user.api_keys || [])],
        credit: updatedUserInfo.user.credit,
        request_count: updatedUserInfo.user.request_count,
        created_date: updatedUserInfo.user?.created_date || '',
        usage: updatedUserInfo.user.usage || [],
        balance_history: updatedUserInfo.user.balance_history || []
      });
    } catch (exception) {
      console.error('Error updating user info:', exception);
      signOut()
    }
  }, [userInfo])

  const getLogs = useCallback(async () => {
    setLogsLoading(true)
    const logs = await getActivityLogs(userInfo?.api_keys?.[0].key || "");
    const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get system timezone
    setLogs(logs.map(log => ({
      ...log,
      // Parse the server time as UTC and convert to local timezone
      timestamp: new Date(log.timestamp + 'Z').toLocaleString('sv-SE', { timeZone: systemTimezone }).replace('T', ' ')
    })));
    setLogsLoading(false)
  }, [userInfo])

  const setUserInfo = (userInfo: IUserInfo) => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    setUserContextInfo(userInfo);
  }
  const signOut = () => {
    localStorage.removeItem('userInfo');
    setUserContextInfo({ email: '', auth_key: [], credit: 0 });
    navigate('/')
  }

  return (
    <AppContext.Provider
      value={{
        openedModal,
        setModalStatus, 
        isConfirmDeleteAPIKey, 
        setIsConfirmDeleteAPIKey, 
        isConfirmDeleteUser, 
        setIsConfirmDeleteUser, 
        isConfirmPasswordReset, 
        setIsConfirmPasswordReset, 
        userInfo, 
        setUserInfo, 
        signOut, 
        updateUserInfo, 
        getLogs, 
        logs, 
        adminToken, 
        setAdminToken,
        adminUsers,
        setAdminUsers,
        logsLoading
      }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the AppContext
export const useAppContext = (): IAppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};