import React, { useEffect, useState } from "react";
import DashboardSection from "../components/dashboard/DashboardSection";
import LogsSection from "../components/dashboard/LogsSection";
import GraphsSection from "../components/dashboard/GraphsSection";
import BillingSection from "../components/dashboard/BillingSection";
import { useAppContext } from "../utils/AppContext";

const ItemsArray = [
  {
    id: 1,
    name: "Dashboard"
  },
  {
    id: 2,
    name: "Logs"
  },
  {
    id: 3,
    name: "Graphs"
  },
  {
    id: 4,
    name: "Billing"
  },
]

const DashBoard: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState("Dashboard");
  const { updateUserInfo, getLogs, logs, logsLoading } = useAppContext();

  const handleSelectItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedItem(e.currentTarget.id);
  };

  useEffect(() => {
    if (selectedItem === "Logs") {
      getLogs()
    } else {
      updateUserInfo()
    }
  }, [selectedItem]);

  return (
    <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto px-5 2xl:px-20 text-left md:text-center relative'>
      <div className="flex justify-center">
        <div className="flex items-center justify-between bg-white w-[441px] h-[48px] rounded-[100px] p-[10px]">
          {
            ItemsArray.map((item) => {
              return (
                <button
                  key={item.id}
                  id={item.name}
                  className={`${selectedItem === item.name ? "bg-[#001AFF] hover:bg-blue-700 text-white" : "bg-white hover:bg-slate-100 text-black"} py-1 px-2 sm:px-6 rounded-[100px]`}
                  onClick={(e) => handleSelectItem(e)}
                >
                  {item.name}
                </button>
              )
            })
          }
        </div>
      </div>
      <div className="flex justify-center w-full">
        {selectedItem === "Dashboard" && <DashboardSection />}
        {selectedItem === "Logs" && <LogsSection logs={logs} getLogs={getLogs} loading={logsLoading} />}
        {selectedItem === "Graphs" && <GraphsSection />}
        {selectedItem === "Billing" && <BillingSection />}
      </div>
      <div className="mt-8 flex justify-center pb-8">
        <button
          className="px-6 py-2 border border-gray-300 rounded-[100px] text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => window.open('https://docs.nichetensor.com/', '_blank')}
        >
          View Documentation
        </button>
      </div>
    </div>
  )
}

export default DashBoard;
