import React from 'react';
import ReactDOM from 'react-dom';
interface IAdminConfirmUserDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsConfirmDeleteUser: (isConfirmDeleteUser: boolean) => void;
}

const AdminConfirmUserDeleteModal: React.FC<IAdminConfirmUserDeleteModalProps> = ({ isOpen, onClose, setIsConfirmDeleteUser }) => {
  if (!isOpen) return null;

  const onClickFunc = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.target === e.currentTarget) {
      setIsConfirmDeleteUser(true);
      onClose();
    }
  }

  const onModalClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center" onClick={onModalClick}>
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
        <h2 className="text-lg font-bold text-gray-900">Confirm Deletion</h2>
        <p className="mt-2 text-gray-600">Are you sure you want to delete this user?</p>
        <div className="mt-4 flex justify-end">
          <button
            className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
            onClick={onClickFunc}
          >
            Delete
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root') as Element
  );
}

export default AdminConfirmUserDeleteModal;