import React, { useState, useMemo, useCallback, useEffect } from "react";
import RefreshIcon from "../../assets/images/refresh.svg";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { ChevronDownIcon as ChevronDownIcon20 } from '@heroicons/react/20/solid';
import { ILog } from "../../utils/apiServices";
import { useAppContext } from "../../utils/AppContext";
import Pagination from "../Pagination";

interface ILogsSectionProps {
  logs: ILog[],
  getLogs: () => void,
  sectionClsName?: string,
  loading: boolean
}

const LogsSection: React.FC<ILogsSectionProps> = ({ logs, getLogs, sectionClsName = "p-8 rounded-[16px] md:rounded-[30px] bg-white mt-36 w-full max-w-[1280px]", loading = false }) => {
  const [rotation, setRotation] = useState(0);
  const [sortColumn, setSortColumn] = useState<keyof ILog | null>("timestamp");
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>("desc");
  const [selectedApiKey, setSelectedApiKey] = useState<string | 'all'>('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(50); // Set the default number of logs per page

  // Get unique API keys from logs
  const apiKeys = useMemo(() => {
    const uniqueKeys = Array.from(new Set(logs.map(log => log.api_key)));
    return ['all', ...uniqueKeys];
  }, [logs]);

  const handleClick = useCallback(() => {
    setRotation(prevRotation => prevRotation + 360);
    getLogs()
  }, [getLogs]);

  const handleSort = useCallback((column: keyof ILog) => {
    if (sortColumn === column) {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else if (sortDirection === 'desc') {
        setSortColumn(null);
        setSortDirection(null);
      } else {
        setSortDirection('asc');
      }
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  }, [sortColumn, sortDirection]);

  const filteredLogs = useMemo(() => {
    return selectedApiKey === 'all' ? logs : logs.filter(log => log.api_key === selectedApiKey);
  }, [logs, selectedApiKey]);

  const sortedLogs = useMemo(() => {
    if (!sortColumn || !sortDirection) return filteredLogs;
    return [...filteredLogs].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredLogs, sortColumn, sortDirection]);

  const paginatedLogs = useMemo(() => {
    const startIndex = (currentPage - 1) * logsPerPage;
    return sortedLogs.slice(startIndex, startIndex + logsPerPage);
  }, [sortedLogs, currentPage, logsPerPage]);

  const totalPages = Math.ceil(sortedLogs.length / logsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const SortIcon = ({ column }: { column: keyof ILog }) => {
    if (sortColumn !== column) return null;
    if (sortDirection === 'asc') return <ChevronUpIcon className="w-4 h-4 inline-block ml-1" />;
    if (sortDirection === 'desc') return <ChevronDownIcon className="w-4 h-4 inline-block ml-1" />;
    return null;
  };

  return (
    <section className={sectionClsName}>
      <div className="flex justify-between items-center mb-6">
        <p className="font-bold text-[42px]">Logs</p>
        <div className="flex items-center">
          <div className="relative mr-4">
            <select
              value={selectedApiKey}
              onChange={(e) => setSelectedApiKey(e.target.value)}
              className="appearance-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              {apiKeys.map((key) => (
                <option key={key} value={key}>
                  {key === 'all' ? 'All API Keys' : key}
                </option>
              ))}
            </select>
            <ChevronDownIcon20 className="pointer-events-none absolute right-3 top-2.5 h-4 w-4 text-gray-400" aria-hidden="true" />
          </div>
          <div className="relative mr-4">
            <select
              value={logsPerPage}
              onChange={(e) => setLogsPerPage(Number(e.target.value))}
              className="appearance-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
          <button onClick={handleClick}>
            <img
              src={RefreshIcon}
              alt="refresh"
              className="w-[40px] h-[40px] transition-transform duration-1000 ease-in-out"
              style={{ transform: `rotate(${rotation}deg)` }}
            />
          </button>
        </div>
      </div>

      {loading && paginatedLogs.length === 0 ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          {paginatedLogs.length > 0 ? (
            <div className="mt-4 overflow-x-auto">
              <div className="min-w-[800px]">
                <div className="grid grid-cols-7 gap-4 border-b border-gray-200 pb-2">
                  <button onClick={() => handleSort('action')} className="text-left font-semibold text-[14px] text-[#00000080] flex items-center w-full px-2">
                    Action <SortIcon column="action" />
                  </button>
                  <button onClick={() => handleSort('details')} className="text-left font-semibold text-[14px] text-[#00000080] flex items-center w-full px-2">
                    Details <SortIcon column="details" />
                  </button>
                  <button onClick={() => handleSort('api_key')} className="text-left font-semibold text-[14px] text-[#00000080] flex items-center w-full px-2">
                    API Key <SortIcon column="api_key" />
                  </button>
                  <button onClick={() => handleSort('status')} className="text-left font-semibold text-[14px] text-[#00000080] flex items-center w-full px-2 justify-center">
                    Status <SortIcon column="status" />
                  </button>
                  <button onClick={() => handleSort('model')} className="text-left font-semibold text-[14px] text-[#00000080] flex items-center w-full px-2">
                    Model <SortIcon column="model" />
                  </button>
                  <button onClick={() => handleSort('cost')} className="text-left font-semibold text-[14px] text-[#00000080] flex items-center w-full px-2">
                    Cost <SortIcon column="cost" />
                  </button>
                  <button onClick={() => handleSort('timestamp')} className="text-left font-semibold text-[14px] text-[#00000080] flex items-center w-full px-2">
                    Timestamp <SortIcon column="timestamp" />
                  </button>
                </div>
                {paginatedLogs.map((log) => (
                  <div key={log._id} className="grid grid-cols-7 gap-4 py-4 border-b border-gray-200 last:border-b-0">
                    <p className={`font-semibold text-[16px] w-5/5 px-2 text-left ${log.status !== 200 ? 'text-red-500' : ''}`}>
                      {log.action}
                    </p>
                    <p className={`text-[14px] w-4/5 px-2 text-left ${log.status !== 200 ? 'text-red-500' : 'text-[#00000080]'}`}>
                      {log.details}
                    </p>
                    <p className={`text-[14px] w-8/8 px-2 text-left whitespace-nowrap overflow-hidden overflow-ellipsis ${log.status !== 200 ? 'text-red-500' : 'text-[#00000080]'}`} title={log.api_key}>
                      {log.api_key}
                    </p>
                    <p className={`text-[14px] w-1/8 px-2 ${log.status === 200 ? 'text-green-500' : 'text-red-500'}`}>
                      {log.status}
                    </p>
                    <p className={`text-[14px] w-1/4 px-2 text-left ${log.status !== 200 ? 'text-red-500' : 'text-[#00000080]'}`}>
                      {log.model}
                    </p>
                    <p className={`text-[14px] w-1/4 px-2 text-left ${log.status !== 200 ? 'text-red-500' : 'text-[#00000080]'}`}>
                      ${log.cost.toFixed(3)}
                    </p>
                    <p className={`text-[14px] w-5/5 px-2 text-left ${log.status !== 200 ? 'text-red-500' : 'text-[#00000080]'}`}>
                      {log.timestamp}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div className="font-medium text-[22px] flex mt-6">
                <p>No Logs found.</p>
              </div>
              <div className="font-normal text-[14px] flex text-[#00000080] mt-4">
                <p className="text-start">Please head over to the API dashboard to generate API keys and get started viewing your logs.</p>
              </div>
            </>
          )}

          <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        </>
      )}
    </section>
  )
}

export default LogsSection;
