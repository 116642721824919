import React, { useState, useEffect } from "react";
import Modal from "../common/Modal";
import TopUpIcon from '../../assets/images/transaction-icon.svg';
import DeleteImg from '../../assets/images/delete.svg';
import StripeIcon from '../../assets/images/stripe.svg';
import AddToBalanceModal from "./AddToBalanceModal";
import { useAppContext } from "../../utils/AppContext";

// Add this dummy data for billing methods
const dummyBillingMethods = [
  { id: 1, type: 'Stripe', lastFour: '4242', expiryDate: '12/24' },
];

const BillingSection: React.FC = () => {
  const [openAddBalanceModal, setOpenAddBalanceModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [billingMethods, setBillingMethods] = useState(dummyBillingMethods);
  const { userInfo } = useAppContext();
  const balanceHistory = userInfo?.balance_history?.slice().reverse() || [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = balanceHistory.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(balanceHistory.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteBillingMethod = (id: number) => {
    setBillingMethods(prevMethods => prevMethods.filter(method => method.id !== id));
  };

  return (
    <section className="p-8 rounded-[16px] md:rounded-[30px] bg-white mt-36 w-[460px]">
      <div className="flex">
        <p className="font-bold text-[42px]">Billing</p>
      </div>
      <div className="flex justify-center items-center w-full gap-2 mt-4 text-[11px] md:text-[14px]">
        <button 
          className="w-1/2 h-[50px] bg-[#001AFF] hover:bg-blue-700 rounded-[100px] py-4 px-6 flex items-center justify-center text-white"
          onClick={() => window.open(process.env.REACT_APP_STRIPE_PAYMENT_LINK, '_blank')}
        >
          Add to Balance
        </button>  
      </div>
      {/* <div className="w-full border border-solid border-[#F2F2F2] mt-4"></div>
      <div className="font-medium text-[14px] flex mt-4">
        <p>Saved Billing Methods</p>
      </div>
      {billingMethods.length > 0 ? (
        <div className="mt-4">
          {billingMethods.map((method) => (
            <div key={method.id} className="flex justify-between items-center py-4 border-b border-gray-200 last:border-b-0">
              <div className="flex items-center space-x-4">
                <div className="w-10 h-10 flex items-center justify-center">
                  <img
                    src={
                      method.type.toLowerCase() === 'stripe' ? StripeIcon :
                      // Fallback to text if no matching icon
                      `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><text x="50%" y="50%" font-size="12" font-weight="bold" text-anchor="middle" dy=".3em" fill="%23000">${method.type.slice(0, 2).toUpperCase()}</text></svg>`
                    }
                    alt={`${method.type} icon`}
                    className="w-10 h-10 object-contain"
                  />
                </div>
                <div className="flex flex-col">
                  <p className="text-[14px]">**** **** **** {method.lastFour}</p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <p className="text-[12px] text-[#00000080]">Last Used {method.expiryDate}</p>
                <button
                  className="p-2 rounded-full transition-colors hover:bg-gray-100"
                  onClick={() => handleDeleteBillingMethod(method.id)}
                  
                >
                  <img src={DeleteImg} alt="delete" className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="font-normal text-[14px] flex justify-center text-[#00000080] mt-4">
          <p>No billing methods found.</p>
        </div>
      )} */}
      <div className="w-full border border-solid border-[#F2F2F2] mt-4"></div>
      <div className="font-medium text-[14px] flex mt-4">
        <p>Transactions</p>
      </div>
      {balanceHistory.length > 0 ? (
        <div className="mt-4">
          {currentTransactions.map((transaction, idx) => (
            <div key={idx} className="flex justify-between items-center py-4 border-b border-gray-200 last:border-b-0">
              <div className="flex items-center space-x-4">
                <div className="w-10 h-10 bg-[#F2F2F2] rounded-full flex items-center justify-center">
                  <img 
                    src={TopUpIcon} 
                    alt={"Top-up"} 
                    className="w-5 h-5"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <p className="font-semibold text-[14px]">Top-up</p>
                    <span className="text-[12px] text-[#00000080]">•</span>
                    <p className="text-[12px] text-[#00000080]">
                      {new Date(transaction.timestamp).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).replace(',', '')}
                    </p>
                  </div>
                </div>
              </div>
              <p className={`text-[14px] font-medium ${transaction.amount > 0 ? 'text-green-600' : 'text-red-600'}`}>
                ${Math.abs(transaction.amount).toFixed(2)}
              </p>
            </div>
          ))}
          {balanceHistory.length > 5 && (
            <div className="flex justify-center items-center mt-4 space-x-2">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  className={`px-3 py-1 rounded ${
                    currentPage === page
                      ? 'bg-[#001AFF] text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  {page}
                </button>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="font-normal text-[14px] flex justify-center text-[#00000080] mt-4">
          <p>No transactions found.</p>
        </div>
      )}
      <AddToBalanceModal 
        isOpen={openAddBalanceModal} 
        onClose={() => setOpenAddBalanceModal(false)} 
      />
    </section>
  )
}

export default BillingSection;
