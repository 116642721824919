import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../common/Input';
import Logo from '../../assets/images/logo-home.svg';
import { useAppContext } from '../../utils/AppContext';
import { isIErrorResponse, isISignResponseData, signin } from '../../utils/apiServices';
import { ModalType } from '../../utils/types';

const loginFields = [
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "email-address",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address"
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password"
  }
]
let fieldsState: { [key: string]: any } = {};
loginFields.forEach(field => fieldsState[field.id] = '');

interface ISigninFormProps {
  onClose: Function
}

function SignInForm({ onClose }: ISigninFormProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loginState, setLoginState] = useState(fieldsState);
  const [errorMessage, setErrorMessage] = useState('');
  const { setModalStatus, setUserInfo } = useAppContext();

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const user = await signin({ email: loginState['email-address'], password: loginState['password'] });
      if (isISignResponseData(user)) {
        setUserInfo({
          email: user.user.email,
          api_keys: [{
            key: user.user._id,
            created: user.user.created_date || ""
          }, ...(user.user.api_keys || [])],
          credit: user.user.credit,
          request_count: user.user.request_count,
          usage: user.user.usage || [],
          balance_history: user.user.balance_history || []
        });
        onClose();
        navigate('/dashboard');
      } else if (isIErrorResponse(user)) {
        setErrorMessage(user.detail);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: {
    target: {
      id: any; value: any;
    };
  }) => {
    setErrorMessage(''); // Reset error message
    setLoginState({ ...loginState, [e.target.id]: e.target.value })
  }

  const handleSignUp = () => {
    setModalStatus(ModalType.SIGNUP);
  }

  const handleForgotPassword = () => {
    // Opens the user's email client with a pre-filled subject and body
    window.location.href = 'mailto:contact@nichetensor.com?subject=Forgot Password&body=I forgot my password.';
  }

  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      <div className="mb-10">
        <div className="flex justify-center">
          <img
            alt=""
            src={Logo} />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p className="text-center text-sm text-gray-600 mt-5">
          Don't have an account yet?{' '}
          <span className="font-medium text-purple-600 hover:text-purple-500 cursor-pointer" onClick={handleSignUp}>
            Sign up
          </span>
        </p>
      </div>
      <div className="-space-y-px">
        {
          loginFields.map(field =>
            <Input
              key={field.id}
              handleChange={handleChange}
              value={loginState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
            />
          )
        }
        {errorMessage && <div className="text-red-500 mb-10">{errorMessage}</div>}
      </div>
      <button
        type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
        disabled={isLoading}
      >
        {isLoading ? 'Signing in...' : 'Sign in'}
      </button>
      <button
        type="button" 
        className="text-sm text-purple-600 hover:text-purple-500 cursor-pointer"
        onClick={handleForgotPassword}
      >
        Forgot Password?
      </button>
    </form>
  );
}

export default SignInForm;