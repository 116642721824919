import React from 'react';
import BgNicheLogo from '../icons/BgNicheLogo';
import { useParallax } from 'react-scroll-parallax';
import Arrow from '../../assets/images/top-right-arrow.svg';

const MoreResourcesSection: React.FC = () => {
  const nicheLogoParallaxRef = useParallax({
    rotate: [0, 360],
  });
  return (
    <section className=" px-5 md:px-20 bg-gray-500 relative pb-[10px] overflow-hidden">
      <div className='absolute absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        <div ref={nicheLogoParallaxRef.ref as React.RefObject<HTMLDivElement>}>
          <BgNicheLogo color='gray' />
        </div>
      </div>
      <div className='flex flex-col items-center pb-[120px] z-10 relative'>
        <h2 className='uppercase text-[32px] md:text-[50px] leading-[32px] md:leading-[63.35px] mt-[80px] md:mt-[120px]'>More resources</h2>
        <div className="flex mt-[40px] md:mt-[80px] gap-[20px] 2xl:gap-[60px] flex-col md:flex-row">
          <a href="https://docs.nichetensor.com/" target="_blank" rel="noopener noreferrer" className="more-resources-link">
            <div className='bg-white rounded-[16px] md:rounded-[30px] w-[335px] 2xl:w-[400px] h-[335px] 2xl:h-[400px] flex flex-col justify-between relative'>
              <div className='flex justify-end arrow-container'>
                <img src={Arrow} alt='Arrow' className='arrow-img' />
              </div>
              <p className='text-[36px] leading-[36px] uppercase bottom-[40px] left-[40px] absolute'>Documents</p>
            </div>
          </a>
          <a href="https://niche-image-studio.vercel.app/" target="_blank" rel="noopener noreferrer" className="more-resources-link">
            <div className='bg-white rounded-[16px] md:rounded-[30px] w-[335px] 2xl:w-[400px] h-[335px] 2xl:h-[400px] flex flex-col justify-between relative'>
              <div className='flex justify-end arrow-container'>
                <img src={Arrow} alt='Arrow' className='arrow-img'/>
              </div>
              <p className='text-[36px] leading-[36px] uppercase bottom-[40px] left-[40px] absolute'>Studio</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default MoreResourcesSection;