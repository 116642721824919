import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../utils/AppContext";
import { useEffect } from "react";

const AdminHeader: React.FC = () => {
  const { adminToken } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!adminToken) {
      navigate('/admin/signin');
    }
  }, [adminToken, navigate]);

  return (
    <>
      {adminToken && (<div className="bg-gray-800 text-white p-4">
        <div className='flex gap-5 items-center'>
          <Link to="/admin">
            <h1 className="text-2xl font-bold">NicheTensor</h1>
          </Link>
          <nav>
            <ul className="flex space-x-4">
              <li className="cursor-pointer">
                <Link to="/admin/users">Users</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>)}
    </>
  )
}

export default AdminHeader;