import React, { useState } from 'react';
import Input from '../common/Input';
import { useAppContext } from '../../utils/AppContext';
import Logo from '../../assets/images/logo-home.svg';
import { changePassword, IErrorResponse, isIErrorResponse, isISignResponseData } from '../../utils/apiServices';
import { ModalType } from '../../utils/types';
import { toast } from 'react-toastify';

const changePasswordFields = [
  {
    labelText: "Current Password",
    labelFor: "current-password",
    id: "current-password",
    name: "current-password",
    type: "password",
    isRequired: true,
    placeholder: "Current Password"
  },
  {
    labelText: "New Password",
    labelFor: "new-password",
    id: "new-password",
    name: "new-password",
    type: "password",
    isRequired: true,
    placeholder: "New Password"
  },
  {
    labelText: "Confirm New Password",
    labelFor: "confirm-password",
    id: "confirm-password",
    name: "confirm-password",
    type: "password",
    isRequired: true,
    placeholder: "Confirm New Password"
  }
]
let fieldsState: { [key: string]: string } = {};
changePasswordFields.forEach(field => fieldsState[field.id] = '');

interface IChangePasswordModalProps {
  onClose: () => void
}

const ChangePasswordModal = ({ onClose }: IChangePasswordModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { setModalStatus, userInfo, updateUserInfo } = useAppContext();
  const [changePasswordState, setChangePasswordState] = useState(fieldsState);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (changePasswordState['new-password'] !== changePasswordState['confirm-password']) {
      setErrorMessage("New passwords do not match.");
      return;
    }
    setErrorMessage('');

    try {
      const user = await changePassword(userInfo.api_keys?.[0].key, { email: userInfo.email, oldPassword: changePasswordState['current-password'], newPassword: changePasswordState['new-password'] });
      if (isISignResponseData(user)) {
        setModalStatus(ModalType.NONE);
        toast.success("Password changed successfully!");
        updateUserInfo();
      } else if (isIErrorResponse(user)) {
        setErrorMessage((user as IErrorResponse).detail);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setChangePasswordState({ ...changePasswordState, [e.target.id]: e.target.value });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-10">
        <div className="flex justify-center">
          <img
            alt=""
            src={Logo} />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Change Password
        </h2>
      </div>
      <div>
        {
          changePasswordFields.map(field =>
            <Input
              key={field.id}
              handleChange={handleChange}
              value={changePasswordState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
            />
          )
        }
        {errorMessage && <div className="text-red-500 mb-10">{errorMessage}</div>}
      </div>
      <button type="submit" className="w-full bg-purple-600 text-white py-2 rounded">
        Change Password
      </button>
    </form>
  );
};

export default ChangePasswordModal;
