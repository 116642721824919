import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../utils/AppContext";
import { adminGetUsers, isIErrorResponse } from "../../utils/apiServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UsersTable from "./UsersTable";
import { IUser } from "../../utils/types";

const AdminUsers: React.FC = () => {
  const { adminToken, adminUsers, setAdminUsers } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  const fetchUsers = useCallback(() => {
    setLoading(true); // Set loading to true before fetching
    adminGetUsers(adminToken).then((res) => {
      setLoading(false); // Set loading to false after fetching
      if (isIErrorResponse(res)) {
        toast.error(res.detail);
        navigate("/admin/signin");
      } else {
        console.log("=== adminGetUsers Result ===>", res);
        setAdminUsers(res.users);
      }
    }).catch((err) => {
      setLoading(false); // Set loading to false on error
      console.log("=== adminGetUsers Error ===>", err);
    });
  }, [adminToken]); // Add dependencies

  useEffect(() => {
    if (adminUsers.length === 0) {
      fetchUsers();
    }
  }, [fetchUsers]);

  return (
    <div className='px-10 pb-20'>
      <h1 className='text-2xl font-bold'>Users({adminUsers.length})</h1>
      {loading && (
        <div className="flex justify-center h-[60vh] items-center">
          <div className="loader animate-spin rounded-full border-t-2 border-b-2 border-gray-900 h-12 w-12"></div>
        </div>
      )}
      {!loading && (
        <UsersTable fetchUsers={fetchUsers} />
      )}
    </div>
  )
}

export default AdminUsers;
