import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../utils/AppContext';
import { adminSignin, IErrorResponse } from '../../utils/apiServices';
import { toast } from 'react-toastify';

const AdminSignin: React.FC = () => {
  const {setAdminToken} = useAppContext()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize navigate

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await adminSignin(email, password)
      if (result.token) {
        setAdminToken(result.token)
        toast.success("Admin Signin Successfully!");
        navigate('/admin'); 
      } else {
        setError((result as unknown as IErrorResponse).detail);
      }
    } catch (err) {
      setError('Failed to sign in. Please try again.');
    }
  };

  return (
    <div className="w-full flex items-center justify-center">
      <div className="flex flex-col items-center w-[400px]">
        <h2 className="text-2xl font-bold mb-4">Admin Sign In</h2>
        <form onSubmit={handleSubmit} className="w-full max-w-sm p-6 border border-gray-300 rounded-lg shadow-md bg-white">
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button type="submit" className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600">
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminSignin;
