import React from 'react'

const AdminDashboard: React.FC = () => {
  return (
    <div className='px-10'>
      <h1 className='text-2xl font-bold'>Dashboard</h1>
    </div>
  )
}

export default AdminDashboard;