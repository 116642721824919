export const getCurrentDateString = () => {
  return new Date().toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  });
}

export const handleMaskString = (key: string, maskedLength: number) => {
  const start = key.slice(0, 4);
  const end = key.slice(-5);
  const maskChar = "*";
  const masked = maskChar.repeat(maskedLength);

  return `${start}${masked}${end}`;
}

export { }