import React, { useCallback } from 'react';
import Modal from './common/Modal';
import { useAppContext } from '../utils/AppContext';
import SignInForm from './modals/SignInForm';
import SignUpForm from './modals/SignUpForm';
import ConfirmModal from './modals/ConfirmModal';
import { ModalType } from '../utils/types';
import ChangePasswordModal from './modals/ChangePasswordModal';
import AdminConfirmUserDeleteModal from './modals/AdminConfirmUserDeleteModal';
import AdminConfirmUserPasswordReset from './modals/AdminConfirmUserPasswordReset';

const ModalContainer: React.FC = () => {
  const {openedModal, setModalStatus, setIsConfirmDeleteAPIKey, setIsConfirmDeleteUser, setIsConfirmPasswordReset} = useAppContext();

  const onClose = useCallback(() => {
    setModalStatus(ModalType.NONE)
  }, [])

  return (
    <>
      <Modal isOpen={openedModal == ModalType.SIGNIN} onClose={onClose}>
        <SignInForm onClose={onClose} />
      </Modal>

      <Modal isOpen={openedModal == ModalType.SIGNUP} onClose={onClose}>
        <SignUpForm onClose={onClose} />
      </Modal>

      <Modal isOpen={openedModal == ModalType.CHANGE_PASSWORD} onClose={onClose}>
        <ChangePasswordModal onClose={onClose}/>
      </Modal>

      <ConfirmModal 
        isOpen={openedModal == ModalType.CONFIRM_DELETE} 
        onClose={onClose} 
        setIsConfirmDeleteAPIKey={setIsConfirmDeleteAPIKey}
      />

      <AdminConfirmUserDeleteModal 
        isOpen={openedModal == ModalType.ADMIN_CONFIRM_DELETE_USER} 
        onClose={onClose} 
        setIsConfirmDeleteUser={setIsConfirmDeleteUser}
      />

      <AdminConfirmUserPasswordReset 
        isOpen={openedModal == ModalType.ADMIN_CONFIRM_PASSWORD_RESET} 
        onClose={onClose} 
        setIsConfirmPasswordReset={setIsConfirmPasswordReset}
      />
    </>
  );
};

export default ModalContainer;