import React, { useState, useEffect } from "react";
import UsdtIcon from '../../assets/images/usdt_icon.png';
import EthIcon from '../../assets/images/eth_icon.svg';
import BtcIcon from '../../assets/images/btc_icon.png';
import UsdIcon from '../../assets/images/dollar_icon.png';
import Modal from "../common/Modal";


interface AddToBalanceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddToBalanceModal: React.FC<AddToBalanceModalProps> = ({ isOpen, onClose }) => {
  const [addBalanceValue, setAddBalanceValue] = useState("");
  const [isShowMinError, setIsShowMinError] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  useEffect(() => {
    if (Number(addBalanceValue) < 2) setIsShowMinError(true);
    else setIsShowMinError(false); 
  }, [addBalanceValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setAddBalanceValue(value);
      setIsShowMinError(parseFloat(value) < 2 && value !== "0");
    }
  }

  const getCurrencySymbol = (currency: string) => {
    switch (currency) {
      case 'USD':
        return <img src={UsdIcon} alt="USD" className="w-6 h-6" />;
      case 'USDT':
        return <img src={UsdtIcon} alt="USDT" className="w-6 h-6" />;
      case 'ETH':
        return <img src={EthIcon} alt="ETH" className="w-6 h-6" />;
      case 'BTC':
        return <img src={BtcIcon} alt="BTC" className="w-6 h-6" />;
      default:
        return '$';
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div>
        <p className="font-semibold text-[20px] mt-1">Add to Balance</p>
      </div>
      <div className="mt-3">
        <p className="text-[14px] text-[#00000080]">Choose Amount</p>
      </div>
      <div className="mt-6">
        <div className="relative">
          <input
            className="appearance-none pl-10 border-gray-300 hover:border-gray-400 transition-colors rounded-md w-full py-2 px-3 text-gray-800 leading-tight outline-none text-[40px]"
            id="AddBalance"
            type="text"
            inputMode="decimal"
            onChange={handleChange}
            value={addBalanceValue} 
          />
          <div className="absolute right-0 inset-y-0 flex items-center pr-2">
            <select
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              className="font-bold text-[16px] bg-transparent border-none outline-none"
            >
              <option value="USD">USD</option>
            </select>
          </div>
          <div className="absolute left-0 inset-y-0 flex items-center pl-1">
            {getCurrencySymbol(selectedCurrency)}
          </div>
        </div>
      </div>
      {isShowMinError && 
        <div className="mt-3">
          <p className="text-[14px] text-[#E50000]">Amount must be greater than $2</p>
        </div>
      }
      <div className="w-full border border-solid border-[#F2F2F2] mt-6"></div>
      <div className="mt-3">
        <p className="text-[14px] text-[#00000080]">Please note: There is a minimum top-up amount of $2 USD</p>
      </div>
      <div className="w-full mt-6 flex justify-center">
        <button className="py-4 px-6 text-white bg-[#001AFF] hover:bg-blue-700 rounded-[100px] w-full h-[50px] flex items-center justify-center text-[14px]">Top-up Balance</button>
      </div>
    </Modal>
  );
};

export default AddToBalanceModal;