import { Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js'; 

Chart.register(...registerables);

const LineChart = ({ data }: { data: any }) => {
  return (
    <Line
      data={data}
      options={{
        responsive: true,
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          y: {
            ticks: {
              callback: function(value: string | number) {
                if (typeof value === 'number') {
                  return `$${value.toFixed(2)}`;
                }
                return value;
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
      style={{ width: '100%', height: '100%' }}
    />
  )
}

export default LineChart;