import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import Header from "./Header";
import ModalContainer from "./ModalContainer";
import AdminDashboard from "../pages/admin/AdminDashboard";
import AdminSignIn from "../pages/admin/AdminSignin";
import Footer from "./Footer";
import DashBoard from "../pages/Dashboard";
import ImageAPI from "../pages/ImageAPI";
import Home from "../pages/Home";
import AdminHeader from "./admin/AdminHeader";
import AdminUsers from "./admin/AdminUsers";
import AdminUserDetail from "../pages/admin/AdminUserDetail";

const AppRoutes: React.FC = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');
  const mainClsName = isAdminRoute ? 'overflow-hidden lg:overflow-visible pt-5 md:pt-10 2xl:pt-20' : 'overflow-hidden lg:overflow-visible pt-5 md:pt-10 2xl:pt-20 min-h-screen';
  return (
    <>
      {!isAdminRoute && <Header />}
      {isAdminRoute && <AdminHeader />}
      <main className={mainClsName}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/image-api' element={<ImageAPI />} />
          <Route path='/dashboard' element={<DashBoard />} />

          {/* Admin Routes */}
          <Route path='/admin' element={<AdminDashboard />} />
          <Route path='/admin/signin' element={<AdminSignIn />} />
          <Route path='/admin/users' element={<AdminUsers />} />
          <Route path='/admin/users/:id' element={<AdminUserDetail />} />

          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </main>
      {!isAdminRoute && <Footer />}
      <ModalContainer />
    </>
  )
}

export default AppRoutes;