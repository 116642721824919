import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from './utils/AppContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './components/AppRoutes';

function App() {

  return (
    <BrowserRouter>
      <AppContextProvider>
        <AppRoutes />
      </AppContextProvider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
