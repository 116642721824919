import { useCallback, useEffect, useState } from "react";
import RefreshIcon from "../../assets/images/refresh.svg";
import { IUser, ModalType } from "../../utils/types";
import { useAppContext } from "../../utils/AppContext";
import { adminResetPassword, isIErrorResponse } from "../../utils/apiServices";
import { adminDeleteUser } from "../../utils/apiServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface IUserTable {
  fetchUsers: () => void;
}

const UsersTable: React.FC<IUserTable> = ({ fetchUsers }) => {
  const [authUsers, setAuthUsers] = useState<IUser[]>([]);
  const [sortBy, setSortBy] = useState<keyof IUser>("email");
  const [sortOrder, setSortOrder] = useState<string>("asc");
  const [rotation, setRotation] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const [userToResetPassword, setUserToResetPassword] = useState<string | null>(null);
  const { setModalStatus, isConfirmDeleteUser, setIsConfirmDeleteUser, isConfirmPasswordReset, setIsConfirmPasswordReset, adminToken, adminUsers } = useAppContext();
  const navigate = useNavigate();

  const sortUsers = useCallback((key: keyof IUser) => {
    const sortedUsers = [...adminUsers].sort((a, b) => {
      if (key === "created_date" && a.created_date && b.created_date) {
        return sortOrder === "asc" ? a.created_date.localeCompare(b.created_date) : b.created_date.localeCompare(a.created_date);
      }
      if (key === "api_keys" && a.api_keys && b.api_keys) {
        return sortOrder === "asc" 
          ? a.api_keys.length - b.api_keys.length 
          : b.api_keys.length - a.api_keys.length;
      }
      if (a?.[key] !== undefined && b?.[key] !== undefined) {
        if (a[key]! < b[key]!) return sortOrder === 'asc' ? -1 : 1;
        if (a[key]! > b[key]!) return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setAuthUsers(sortedUsers);
    setSortBy(key);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  }, [adminUsers, sortBy, sortOrder]);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = authUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(adminUsers.length / usersPerPage);

  useEffect(() => {
    sortUsers(sortBy);
  }, [adminUsers]);

  const handleRefresh = useCallback(() => {
    setRotation(prevRotation => prevRotation + 360);
    fetchUsers();
  }, [fetchUsers]);

  const handleDeleteUser = useCallback((id: string) => {
    setUserToDelete(id);
    setModalStatus(ModalType.ADMIN_CONFIRM_DELETE_USER);
  }, [adminUsers]); 

  const handleResetPassword = useCallback((id: string) => {
    setUserToResetPassword(id);
    setModalStatus(ModalType.ADMIN_CONFIRM_PASSWORD_RESET);
  }, [adminUsers]);

  const viewUserDetail = useCallback((id: string) => {
    navigate(`/admin/users/${id}`);
  }, [adminUsers]);

  useEffect(() => {
    if (isConfirmDeleteUser && userToDelete) {
      adminDeleteUser(adminToken, userToDelete).then((res) => {
        if (isIErrorResponse(res)) {
          toast.error(res.detail);
        } else {
          toast.success("User deleted successfully");
          fetchUsers();
        }
      }).catch((err) => {
        console.log("=== adminDeleteUser Error ===>", err);
      }).finally(() => {
        setIsConfirmDeleteUser(false);
        setUserToDelete(null);
      });
    }
  }, [isConfirmDeleteUser]);

  useEffect(() => {
    if (isConfirmPasswordReset && userToResetPassword) {
      adminResetPassword(adminToken, userToResetPassword).then((res) => {
        if (isIErrorResponse(res)) {
          toast.error(res.detail);
        } else {
          console.log("=== adminResetPassword res ===>", res);
          toast.success("Password reset successfully. New password is: " + res.password, { autoClose: false });
        }
      }).catch((err) => {
        console.log("=== adminResetPassword Error ===>", err);
      }).finally(() => {
        setIsConfirmPasswordReset(false);
        setUserToResetPassword(null);
      });
    }
  }, [isConfirmPasswordReset]);

  return (
    <div className="overflow-x-auto">
      <div className="flex justify-end mb-5">
        <div className="relative mr-4">
          <select
            value={usersPerPage}
            onChange={(e) => setUsersPerPage(Number(e.target.value))}
            className="appearance-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div>
          <button className="text-white py-1 px-3 rounded" onClick={handleRefresh}>
            <img
              src={RefreshIcon}
              alt="refresh"
              className="w-[40px] h-[40px] transition-transform duration-1000 ease-in-out"
              style={{ transform: `rotate(${rotation}deg)` }}
            />
          </button>
        </div>
      </div>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border border-gray-300 text-left">ID</th>
            <th className="py-2 px-4 border border-gray-300 text-left cursor-pointer" onClick={() => sortUsers('email')}>
              Email {sortOrder === 'asc' && sortBy === 'email' ? '↑' : sortOrder === 'desc' && sortBy === 'email' ? '↓' : ''}
            </th>
            <th className="py-2 px-4 border border-gray-300 text-left cursor-pointer" onClick={() => sortUsers('created_date')}>
              Date {sortOrder === 'asc' && sortBy === 'created_date' ? '↑' : sortOrder === 'desc' && sortBy === 'created_date' ? '↓' : ''}
            </th>
            <th className="py-2 px-4 border border-gray-300 text-left cursor-pointer" onClick={() => sortUsers('credit')}>Credits</th>
            <th className="py-2 px-4 border border-gray-300 text-left cursor-pointer" onClick={() => sortUsers('request_count')}>Request Count</th>
            <th className="py-2 px-4 border border-gray-300 text-left cursor-pointer" onClick={() => sortUsers('api_keys')}>API Keys Count</th>
            <th className="py-2 px-4 border border-gray-300 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((item, index) => (
            <tr key={index} className="hover:bg-gray-100" onClick={() => viewUserDetail(item._id)}>
              <td className="py-2 px-4 border border-gray-300"><div className="w-[384px]">{item._id}</div></td>
              <td className="py-2 px-4 border border-gray-300">{item.email}</td>
              <td className="py-2 px-4 border border-gray-300">{new Date(item.created_date).toLocaleDateString()}</td>
              <td className="py-2 px-4 border border-gray-300">{item?.credit}</td>
              <td className="py-2 px-4 border border-gray-300">{item?.request_count}</td>
              <td className="py-2 px-4 border border-gray-300">{item?.api_keys?.length}</td>
              <td className="py-2 px-4 border border-gray-300">
                <div className="flex gap-2">
                  <div>
                    <button
                      className="bg-blue-500 text-white py-1 px-3 rounded"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteUser(item._id)
                      }}
                    >
                      Delete User
                    </button>
                  </div>
                  <div>
                    <button
                      className="bg-blue-500 text-white py-1 px-3 rounded"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleResetPassword(item._id)
                      }}
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => setCurrentPage(index + 1)}
            className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  )
}

export default UsersTable;
