import { useParams } from "react-router-dom";
import { useAppContext } from "../../utils/AppContext";
import AdminUserDetailCard from "../../components/admin/AdminUserDetailCard";
import AdminUserAPIKeysCard from "../../components/admin/AdminUserAPIKeysCard";
import LogsSection from "../../components/dashboard/LogsSection";
import { useCallback, useEffect, useState } from "react";
import { getActivityLogs, ILog, isIErrorResponse } from "../../utils/apiServices";
import { toast } from "react-toastify";
import LineChart from "../../components/common/LineChart";
import { Bar } from "react-chartjs-2";
import TopUpIcon from '../../assets/images/transaction-icon.svg';

const AdminUserDetail: React.FC = () => {
  const { id } = useParams();
  const { adminUsers } = useAppContext();
  const user = adminUsers.find((user) => user._id === id);
  const [logs, setLogs] = useState<ILog[]>([])
  const [loading, setLoading] = useState(false)

  const getLogs = useCallback(async () => {
    setLoading(true)
    const logs = await getActivityLogs(user?._id || "");
    const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get system timezone
    if (isIErrorResponse(logs)) {
      setLogs([])
      toast.error(logs.detail)
    } else {
      setLogs(logs.map(log => ({
        ...log,
        // Parse the server time as UTC and convert to local timezone
        timestamp: new Date(log.timestamp + 'Z').toLocaleString('sv-SE', { timeZone: systemTimezone }).replace('T', ' ')
      })));
    }
    setLoading(false)
  }, [user?._id])

  useEffect(() => {
    getLogs()
  }, [getLogs])

  const usageData = user?.usage
  // Prepare data for the line graph
  const months = Array.from({ length: 12 }, (_, i) => new Date(new Date().setMonth(new Date().getMonth() - i)).toLocaleString('default', { month: 'long' })).reverse();
  const creditValues: number[] = new Array(12).fill(user?.credit);
  const currentMonth = new Date().getMonth();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  for (let index = 10; index >= 0; index--) {
    const monthUsage = usageData?.filter(usage => {
      const usageDate = new Date(usage.timestamp);
      return usageDate.getMonth() === ((12 + currentMonth - (10 - index)) % 12) && usageDate >= oneYearAgo;
    });
    const monthTransactions = user?.balance_history?.filter(transaction => {
      const transactionDate = new Date(transaction.timestamp);
      return transactionDate.getMonth() === ((12 + currentMonth - (10 - index)) % 12) && transactionDate >= oneYearAgo;
    });
    const totalUsed = monthUsage?.reduce((acc, usage) => acc + usage.credit_cost, 0);
    const totalTopUp = monthTransactions?.reduce((acc, transaction) => acc + transaction.amount, 0);
    const previousValue = creditValues[index + 1];
    creditValues[index] = previousValue + (totalUsed || 0) - (totalTopUp || 0);
  }

  const createdDate = new Date(user?.created_date || '');
  const currentDate = new Date();
  const monthDifference = (currentDate.getFullYear() - createdDate.getFullYear()) * 12 + (currentDate.getMonth() - createdDate.getMonth());
  if (monthDifference < 10) {
    // Reduce months and creditValues to match the actual data
    months.splice(0, 10 - monthDifference);
    creditValues.splice(0, 10 - monthDifference);
  }

  const yearData = {
    labels: months,
    datasets: [
      {
        label: 'Balance',
        data: creditValues,
        borderColor: '#001AFF',
        backgroundColor: 'rgba(0, 26, 255, 0.2)',
        fill: true,
      },
    ],
  };

  // Prepare data for the real-time graph
  const currentTime = new Date();
  const times = Array.from({ length: 23 }, (_, i) => {
    const hours = (23 + currentTime.getHours() - i) % 24;
    return `${hours.toString().padStart(2, '0')}:00`;
  }).reverse();

  times.push(`${currentTime.getHours().toString().padStart(2, '0')}:${currentTime.getMinutes().toString().padStart(2, '0')}`);

  const creditRealtimeData: number[] = new Array(24).fill(user?.credit);
  const currentHour = new Date().getHours();
  const oneDayAgo = new Date();
  oneDayAgo.setHours(oneDayAgo.getHours() - 24);
  for (let index = 22; index >= 0; index--) {
    const hourUsage = usageData?.filter(usage => {
      const usageDate = new Date(usage.timestamp + 'Z');
      return usageDate.getHours() === ((24 + currentHour - (22 - index)) % 24) && usageDate >= oneDayAgo;
    });
    const hourTransactions = user?.balance_history?.filter(transaction => {
      const transactionDate = new Date(transaction.timestamp + 'Z');
      return transactionDate.getHours() === ((24 + currentHour - (22 - index)) % 24) && transactionDate >= oneDayAgo;
    });
    const totalUsed = hourUsage?.reduce((acc, usage) => acc + usage.credit_cost, 0);
    const totalTopUp = hourTransactions?.reduce((acc, transaction) => acc + transaction.amount, 0);
    const previousValue = creditRealtimeData[index + 1];
    creditRealtimeData[index] = previousValue + (totalUsed || 0) - (totalTopUp || 0);
  }

  const dayTimeData = {
    labels: times,
    datasets: [
      {
        label: 'Balance',
        data: creditRealtimeData,
        borderColor: '#001AFF',
        backgroundColor: 'rgba(0, 26, 255, 0.2)',
        fill: true,
      },
    ],
  };

  // Define the type for endpointUsageData
  const model_names = usageData?.map(usage => usage.model_name);
  const uniqueModels = Array.from(new Set(model_names));
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const endpointUsageData: { [key: string]: number } = {};
  for (let index = 0; index < uniqueModels.length; index++) {
    endpointUsageData[uniqueModels[index]] = usageData?.filter(usage => usage.model_name === uniqueModels[index]).length || 0;
  }
  // Prepare data for the bar chart
  const barChartData = {
    labels: uniqueModels,
    datasets: [
      {
        label: 'Usage Count',
        data: uniqueModels.map(endpoint => endpointUsageData[endpoint]),
        backgroundColor: 'rgba(0, 123, 255, 0.5)',
      },
    ],
  };

  const balanceHistory = user?.balance_history?.slice().reverse() || [];
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = balanceHistory.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(balanceHistory.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="px-10 pb-20">
      <div className="flex gap-4">
        {user ? <AdminUserDetailCard user={user} /> : <p>User not found</p>}
        {user ? <AdminUserAPIKeysCard user={user} /> : <p>User not found</p>}
      </div>
      <div className="flex gap-4">
        <div className="w-full rounded-[16px] md:rounded-[30px] p-8 bg-white mt-10 flex flex-col items-start w-[50%]">
          <p className="font-semibold text-[20px]">Track user spend for each month</p>
          <div className="w-full rounded-[15px] mt-4">
            <LineChart data={yearData} />
          </div>
        </div>
        <div className="w-full rounded-[16px] md:rounded-[30px] p-8 bg-white mt-10 flex flex-col items-start w-[50%]">
          <p className="font-semibold text-[20px]">Current Account Balance in 24 hours</p>
          <div className="w-full mt-4">
            <LineChart data={dayTimeData} />
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-full rounded-[16px] md:rounded-[30px] p-8 bg-white mt-10 flex flex-col items-start w-[60%]">
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col items-start">
              <p className="font-semibold text-[14px] sm:text-[20px]">Current Usage by Model</p>
              <p className="font-normal text-[12px] sm:text-[14px] text-[#00000080]">Track models usage in real-time</p>
            </div>
            <div className="flex py-1 sm:py-2 px-[6px] sm:px-4 rounded-[100px] text-[#001AFF] text-[12px] sm:text-[14px] bg-[#e5e7eb]">
              <p className="text-center">Since {new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}, {new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString('default', { day: 'numeric', month: 'long' })}</p>
            </div>
          </div>
          <Bar data={barChartData} options={{ responsive: true }} />
        </div>
        <div className="p-8 rounded-[16px] md:rounded-[30px] bg-white mt-10 w-[40%]">
          <div className="font-medium text-[14px] flex mt-4">
            <p>Transactions</p>
          </div>
          {balanceHistory.length > 0 ? (
            <div className="mt-4">
              {currentTransactions.map((transaction, idx) => (
                <div key={idx} className="flex justify-between items-center py-4 border-b border-gray-200 last:border-b-0">
                  <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 bg-[#F2F2F2] rounded-full flex items-center justify-center">
                      <img
                        src={TopUpIcon}
                        alt={"Top-up"}
                        className="w-5 h-5"
                      />
                    </div>
                    <div className="flex flex-col">
                      <div className="flex items-center space-x-2">
                        <p className="font-semibold text-[14px]">Top-up</p>
                        <span className="text-[12px] text-[#00000080]">•</span>
                        <p className="text-[12px] text-[#00000080]">
                          {new Date(transaction.timestamp).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).replace(',', '')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className={`text-[14px] font-medium ${transaction.amount > 0 ? 'text-green-600' : 'text-red-600'}`}>
                    ${Math.abs(transaction.amount).toFixed(2)}
                  </p>
                </div>
              ))}
              {balanceHistory.length > 5 && (
                <div className="flex justify-center items-center mt-4 space-x-2">
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={`px-3 py-1 rounded ${currentPage === page
                          ? 'bg-[#001AFF] text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        }`}
                    >
                      {page}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="font-normal text-[14px] flex justify-center text-[#00000080] mt-4">
              <p>No transactions found.</p>
            </div>
          )}
        </div>
      </div>
      <LogsSection logs={logs} getLogs={getLogs} sectionClsName={"p-8 rounded-[16px] md:rounded-[30px] bg-white mt-10 w-full"} loading={loading} />
    </div>
  )
}

export default AdminUserDetail;