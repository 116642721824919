export interface IUserInfo {
  email: string;
  credit: number; // number of credits
  request_count: number; // number of requests made
  created_date?: string;
  api_keys?: Array<{
    key: string;
    created: string;
  }>;
  usage: Array<{
    model_name: string;
    pipeline_type: string;
    credit_cost: number;
    timestamp: string;
  }>;
  balance_history: Array<{
    amount: number;
    timestamp: string;
  }>;
}

export enum ModalType {
  SIGNIN = 'signIn',
  SIGNUP = 'signUp',
  CHANGE_PASSWORD = 'changePassword',
  CONFIRM_DELETE = 'confirmDelete',
  ADMIN_CONFIRM_DELETE_USER = 'adminConfirmDeleteUser',
  ADMIN_CONFIRM_PASSWORD_RESET = 'adminConfirmPasswordReset',
  NONE = '',
}

export interface IAPIKey {
  key: string;
  created: string;
}

export interface IBalanceHistory {
  amount: number;
  timestamp: string;
}

export interface IUsage {
  model_name: string;
  pipeline_type: string;
  credit_cost: number;
  timestamp: string;
}

export interface IUser {
  _id: string;
  email?: string;
  request_count: number;
  credit: number;
  created_date: string;
  api_keys?: IAPIKey[];
  balance_history?: IBalanceHistory[];
  usage?: IUsage[];
}
