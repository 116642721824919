import React, { useState } from 'react';
import Input from '../common/Input';
import Logo from '../../assets/images/logo-home.svg';
import { useAppContext } from '../../utils/AppContext';
import { IErrorResponse, isIErrorResponse, isISignResponseData, signup } from '../../utils/apiServices';
import { ModalType } from '../../utils/types';

const signupFields = [
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "email-address",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address"
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password"
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirm-password",
    id: "confirm-password",
    name: "confirm-password",
    type: "password",
    autoComplete: "confirm-password",
    isRequired: true,
    placeholder: "Confirm Password"
  }
]
let fieldsState: { [key: string]: string } = {};
signupFields.forEach(field => fieldsState[field.id] = '');

interface ISignUpFormProps {
  onClose: Function
}

function SignUpForm({ onClose }: ISignUpFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [signupState, setSignupState] = useState(fieldsState);
  const [errorMessage, setErrorMessage] = useState('');
  const handleChange = (e: { target: { id: any; value: any; }; }) => {
    setErrorMessage(''); // Reset error message
    setSignupState({ ...signupState, [e.target.id]: e.target.value });
  }
  const { setModalStatus, setUserInfo } = useAppContext();

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(''); // Reset error message
    if (signupState['password'] !== signupState['confirm-password']) {
      setErrorMessage('Passwords do not match');
      setIsLoading(false);
      return;
    }
    try {
      const user = await signup({ email: signupState['email-address'], password: signupState['password'] });
      if (isISignResponseData(user)) {
        setUserInfo({
          email: user.user.email,
          api_keys: [{
            key: user.user._id,
            created: user.user.created_date || ""
          }, ...(user.user.api_keys || [])],
          credit: user.user.credit,
          request_count: user.user.request_count,
          usage: user.user.usage || [],
          balance_history: user.user.balance_history || []
        });
        onClose();
      } else if (isIErrorResponse(user)) {
        setErrorMessage((user as IErrorResponse).detail);
      }
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = () => {
    setModalStatus(ModalType.SIGNIN);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-10">
        <div className="flex justify-center">
          <img
            alt=""
            src={Logo} />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign Up to NicheTensor
        </h2>
        <p className="text-center text-sm text-gray-600 mt-5">
          Already have an account?{' '}
          <span className="font-medium text-purple-600 hover:text-purple-500 cursor-pointer" onClick={handleSignIn}>
            Sign In
          </span>
        </p>
      </div>
      <div className="">
        {
          signupFields.map(field =>
            <Input
              key={field.id}
              handleChange={handleChange}
              value={signupState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
            />
          )
        }
        {errorMessage && <div className="text-red-500 mb-10">{errorMessage}</div>}
      </div>
      <button
        type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
        disabled={isLoading}
      >
        {isLoading ? 'Signing up...' : 'Sign up'}
      </button>
    </form>
  );
}

export default SignUpForm;