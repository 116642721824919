import { IUser } from "../../utils/types";
import KeyIcon from '../../assets/images/key-icon.svg';

const AdminUserAPIKeysCard: React.FC<{ user: IUser }> = ({ user }) => {
  return (
    <div className="p-5 sm:p-10 rounded-[16px] md:rounded-[30px] bg-white flex flex-col items-start w-[100%]">
      <p className="font-semibold text-[20px] mb-4">API Keys</p>
      {user?.api_keys?.map((value, idx) => (
        <div key={value.key} className="flex justify-between items-center py-4 border-b border-gray-200 last:border-b-0 gap-10">
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 bg-[#F2F2F2] rounded-full flex items-center justify-center">
              <img src={KeyIcon} alt="API Key" className="w-5 h-5 text-[#001AFF]" />
            </div>
            <div className="flex items-center space-x-2">
              <p className="font-semibold text-[14px]">API Key {idx + 1}</p>
              <span className="text-[12px] text-[#00000080]">•</span>
              <p className="text-[12px] text-[#00000080]">
                {new Date(value.created).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).replace(',', '')}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <p className="text-[14px] font-mono">{value.key}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default AdminUserAPIKeysCard;